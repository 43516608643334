@media screen and (min-width: 320px) {
    .verify-image-container img {
        width: 100% !important;
    }

    *::-webkit-scrollbar {
        display: none;
    }

    .more-button {
        right: -20px;
        top: 15px;
        float: right;
    }

    .back-button-icon-info {
        position: absolute;
        top: 50px;
        left: 20px;
    }

    .closed-edite-profile, .closed-app-box-notification, .closed-app-box {
        height: 12vh;
    }
}

@media (max-height: 768px) {
    .swiper-pagination {
        display: none;
    }
}

@media screen and (min-width: 375px) {
    .login-header {
        font-size: 24px;
    }

    .user-profile {
        display: block !important;
    }
}

@media screen and (max-width: 375px) {
    .notification-content {
        display: flex;
        flex-direction: column;
    }

    main {
        padding: 0 0 100px 0;
    }

    .user-title {
        flex-direction: column;

    }

    .notification-comment, .time {
        margin: 0 0 0 10px !important;
    }

    .message-input-wrapper {
        margin: 0 !important;
    }

    .message-input-wrapper button[aria-label="emoji-button"] {
        padding: 0 !important;
    }

    .react-audio-player {
        max-width: 210px !important;
    }
}


@media screen and (max-width: 425px) {
    .app-box-profile {
        padding: 7px 0 !important;
    }
}

@media screen and (min-width: 425px) {
    .app-box {
        padding: 10px 15px;
        margin-top: 20px;
    }

    .app-box-notification {
        padding: 0 15px;
    }

    .user-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .change-profile {
        right: 0 !important;
    }

    .app-box-profile {
        padding: 10px 0 !important;
    }

    .edite-profile {
        margin: 20px 0 0 0 !important;
    }

    main {
        padding-bottom: 100px;
    }
}

@media screen and (min-width: 768px) {
    .user-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profile-detail {
        flex-direction: row !important;
        margin: 0 !important;;
    }

    .profile-statistics-xs {
        display: none !important;
    }

    .profile-statistics-sm {
        display: flex !important;
    }

    .profile-container, .posts-container {
        margin: 10px 7px 0;
    }

    .posts-container {
        margin: 0 30px;
    }

    .detail-user-name {
        font-size: 20px !important;
    }

    .verify-image-container img {
        width: 40% !important;
    }

}

@media screen and (min-width: 899px) {
    .active {
        transition: unset !important;
        background-color: unset !important;
        border-bottom: unset !important;
        z-index: unset !important;
    }

    .hidden {
        z-index: unset !important;
        border-bottom: unset !important;
        box-shadow: unset !important;
        transition: unset !important;
    }

    .mobile-container {
        margin: unset !important;
    }

    *::-webkit-scrollbar {
        display: block;
    }

    .more-button {
        right: -15px;
        top: 35px;
        float: right;
    }


    .edite-profile {

        height: initial;

    }

    main {
        padding-bottom: 0px;
    }

    .auth-bg {
        height: 100%;
    }
}

@media screen and (min-width: 1200px) {
    .auth {
        background: url("../assets/images/svg/bg-all.png") no-repeat;
        background-size: cover;
        background-position-x: center;
        background-position-y: center;
    }

    .edite-profile {
        margin: 85px 0 0 0 !important;
    }

    .app-box {
        margin-top: 0;
    }

    .user-info {
        padding: 0 30px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .back-button-icon-info {
        position: absolute;
    }

    .closed-edite-profile {
        height: calc(100vh - 100px);
    }

    .closed-app-box-notification .closed-app-box {
        height: 45vh;
    }

    .closed-app-box {
        height: calc(50vh - 80px);
    }

    .closed-app-box-notification {
        height: 45vh;
    }

    .edite-profile {

        height: calc(100vh - 100px);

    }
}

@media screen and (min-width: 1400px) {
    .profile-container {
        margin: 35px 50px 0;
    }
}

@media screen and (min-width: 1700px) {
    .auth {
        background: url("../assets/images/svg/bg-all-1800.png") no-repeat;
        background-size: cover;
        background-position-x: center;
        background-position-y: center;
    }
}

@media screen and (max-width: 1200px) {
    .closed-edite-profile button, .closed-app-box button, .closed-app-box-notification button {
        rotate: 271deg;
    }

    .chat-media-item {
        height: 100% !important;
    }
}

@media screen and (max-width: 900px) {
    .chat-message-box > .MuiBox-root {
        padding-top: 30px;
    }

}

@media screen and (max-width: 600px) {
    .chat-user-information {
        height: 100px !important;
    }
}